<template>
  <v-bottom-sheet
    v-model="showCashout"
    persistent
    inset
    max-width="600px"
  >
    <v-sheet
      class="rounded-t-xl"
    >
      <v-container v-if="!CashoutDone">
        <v-toolbar
          dense
          flat
          color="transparent"
        >
          <v-btn
            icon
            @click="showCashout = false"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>{{ $t('jianfen') }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-sheet
          color="nav_bg"
          class="pa-3 mt-3 rounded-t-xl"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="require('../../assets/img/avatar/'+transitem.avatar+'.png')" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ transitem.username }}</v-list-item-title>
              <v-list-item-action-text class="opacity-5">
                UID: {{ transitem.id }}
              </v-list-item-action-text>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>
                <div class="d-flex align-center">
                  <img
                    width="16"
                    height="16"
                    src="../../assets/img/icons/coin.svg"
                    class="mr-1"
                  >
                  {{ transitem.assets?.available }}
                </div>
              </v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-sheet>

        <v-sheet
          color="app_bg"
          class="pa-6 pt-3 mb-3 rounded-b-xl"
        >
          <v-subheader>
            <v-icon
              small
              left
            >
              mdi-database-arrow-down-outline
            </v-icon>
            {{ $t('jianfen_t2') }}
          </v-subheader>

          <v-text-field
            v-model.number="transinfo.amount"
            type="number"
            outlined
            background-color="#fff"
            rounded
            autofocus
            height="56"
            hide-details="auto"
            :placeholder="$t('jianfen_shuru')"
          >
            <template v-slot:prepend-inner>
              <img
                width="22"
                height="22"
                src="../../assets/img/icons/coin.svg"
              >
            </template>
          </v-text-field>

          <v-subheader class="mt-3">
            {{ $t('beizhu') }}
          </v-subheader>
          <v-text-field
            v-model.number="transinfo.remark_money"
            type="number"
            filled
            background-color="#fff"
            :label="$t('jine')"
            rounded
            hide-details="auto"
          />
          <v-textarea
            v-model="transinfo.remark"
            filled
            rounded
            background-color="#fff"
            :label="$t('beizhu_neirong')"
            hide-details="auto"
            class="mt-3"
          />
        </v-sheet>

        <v-btn
          block
          x-large
          rounded
          color="primary"
          dark
          class="mt-5"
          @click="cashout"
        >
          {{ $t('tijiao') }}
        </v-btn>
      </v-container>

      <v-container
        v-if="CashoutDone"
        class="pt-6"
      >
        <div class="text-center">
          <v-avatar
            color="primary"
            size="64"
            class="rounded-xl"
          >
            <v-icon
              dark
              large
            >
              mdi-check-bold
            </v-icon>
          </v-avatar>
        </div>

        <v-sheet
          color="nav_bg"
          class="pt-10 pb-6 mt-n7 rounded-t-xl"
        >
          <div class="text-h5 text-center primary--text">
            <h3 class="my-2">
              -{{ transinfo.amount }}
            </h3>
            {{ $t('jianfen_chenggong') }}
          </div>
        </v-sheet>

        <v-sheet
          class="py-3 mt-3 rounded-b-xl"
        >
          <v-list
            color="transparent"
          >
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="require('../../assets/img/avatar/'+transitem.avatar+'.png')" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ transitem.username }}</v-list-item-title>
                <v-list-item-subtitle class="opacity-5">
                  UID: {{ transitem.id }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                >
                  <v-icon>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider class="opacity-3 my-3" />

            <div class="text-body-2">
              <v-list-item>
                <v-list-item-content class="">
                  {{ $t('dingdanbianhao') }}
                </v-list-item-content>
                <v-list-item-action>
                  <div>
                    {{ orderinfo.orderno }}
                    <v-btn
                      icon
                      small
                      @click="doCopy(orderinfo.orderno)"
                    >
                      <v-icon small>
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-content class="">
                  {{ $t('tijiao_shijian') }}
                </v-list-item-content>
                <v-list-item-action>
                  {{ orderinfo.created }}
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-content class="">
                  {{ $t('beizhu_jine') }}
                </v-list-item-content>
                <v-list-item-action>
                  $ {{ transinfo.remark_money }}
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-content class="">
                  {{ $t('beizhu_neirong') }}
                </v-list-item-content>
                <v-list-item-content class="text-right">
                  {{ transinfo.remark }}
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-sheet>

        <v-btn
          block
          x-large
          rounded
          color="primary"
          dark
          class="mt-5"
          @click="transdone"
        >
          {{ $t('wancheng') }}
        </v-btn>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'Cashout',
  props: {
    fromRole: {
      type: String,
      required: true
    },
    transitem: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showCashout: true,
    CashoutDone: false,
    agentinfo: {},
    transinfo: {
      amount: null,
      remark_money: null,
      remark: '',
    },
  }),
  computed: {

  },
  watch:{
    showCashout: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if(newVal==false){
          this.$emit('close_cashout')
        }
      }
    },
    CashoutDone: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if(newVal==false && oldVal==true){
          this.transdone()
        }
      }
    },
  },
  created() {
    
  },
  mounted() {

  },
  methods: {

    cashout() {
      if(this.transitem.assets.available<this.transinfo.amount){
          this.$snackbar.warning('Insufficient balance')
          return false
        }
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          from_id: this.transitem.id,
          from_role: this.fromRole,
          amount: this.transinfo.amount,
          remark_money: this.transinfo.remark_money,
          remark: this.transinfo.remark,
        }
        this.$server.cashout(paramObj).then((response) => {
          if(response.code==200){
            this.orderinfo = response.data
            this.CashoutDone = true
          }
        })
    },
    transdone() {
      this.$router.go(0)
    },
    doCopy: function (text) {
        text = ''+text//转string
        this.$copyText(text).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('copy_ok'))
        }, function (e) {
          console.log(e)
        })
    },
  },
};
</script>